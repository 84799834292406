
button[type=submit] {

  background-color: #4b4f57;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  justify: center;
}